import { Interval, Duration } from "luxon";

export function generateFlowersUntil({ generator, end, versions }) {
  const sorted = Object.values(versions).sort((versionA, versionB) =>
    versionA.from.equals(versionB.from)
      ? 0
      : versionA.from < versionB.from
      ? -1
      : 1
  );

  console.log(sorted);

  // Map to {days: DateTime[], pool: string[]}[]
  const daysWithPools = sorted
    .map((version, i, arr) => {
      // Do not generate for versions that are past the end date
      if (version.from > end) {
        return undefined;
      }
      const nextVersionStart = arr[i + 1]?.from;
      const generateUntil = nextVersionStart
        ? end > nextVersionStart
          ? nextVersionStart
          : end
        : end;
      console.log({ from: version.from, generateUntil, end, nextVersionStart });
      return {
        // The end of an interval is either the end date, or the next verion's start
        days: Interval.fromDateTimes(version.from, generateUntil)
          // Split the interval into intravals of 1 day
          .splitBy(Duration.fromObject({ days: 1 }))
          // And then get each day
          .map((interval) => interval.start),
        pool: version.pool,
      };
    })
    .filter(Boolean);

  // Generate flowers for each day, based on the pool
  // We flatten at the end because versions don't matter after generating
  const allDaysWithFlowers = daysWithPools.flatMap(({ days, pool }) =>
    days.map((day) => {
      return {
        // Keep dateTime info for future grouping
        dateTime: day,
        // 3 flowers per day
        emoji: [1, 2, 3].map((_) => pool[generator.nextInt(pool.length)]),
      };
    })
  );

  return allDaysWithFlowers;
}
