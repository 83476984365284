import { useSyncExternalStore } from "react";

const QUERY = "(prefers-reduced-motion: no-preference)";

function subscribe(listener) {
  const mediaQueryList = window.matchMedia(QUERY);
  mediaQueryList.addEventListener("change", listener);
  return () => {
    mediaQueryList.removeEventListener("change", listener);
  };
}

/**
 * Returns whether the user prefers reduced motion or not
 */
export function usePrefersReducedMotion() {
  return useSyncExternalStore(
    subscribe,
    () => !window.matchMedia(QUERY).matches,
    () => false
  );
}
