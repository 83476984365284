import confetti from "canvas-confetti";

// @see https://www.kirilv.com/canvas-confetti/
export function fireConfetti() {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

function fire(particleRatio, opts) {
  const count = 200;
  confetti({
    origin: { y: 0.7 },
    colors: ["#53257D", "#DD7380", "#f55924", "#dbc1ff"],
    particleCount: Math.floor(count * particleRatio),
    disableForReducedMotion: true,
    ...opts,
  });
}

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function startSnow() {
  const duration = 15 * 1000;
  const animationEnd = Date.now() + Infinity;
  let skew = 1;

  function snow() {
    var timeLeft = animationEnd - Date.now();
    var ticks = Math.max(200, 500 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        // since particles fall down, skew start toward the top
        y: Math.random() * skew - 0.2,
      },
      colors: ["#ffffff"],
      shapes: ["circle"],
      gravity: randomInRange(0.4, 0.6),
      scalar: randomInRange(0.4, 1),
      drift: randomInRange(-0.4, 0.4),
      disableForReducedMotion: true,
    });

    if (timeLeft > 0) {
      requestAnimationFrame(snow);
    }
  }

  snow();
}
